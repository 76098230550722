import { useState } from 'react';
import Copyright from '../components/common/copyright/copyright';
import Header from '../components/header/header';
import SideBar from "../components/sidebar";
import { AllRoutesData } from "../Routes/routes";
import "./layout.css";

const Layout = ({ children }) => {

  const [sidebarType, setSidebarType] = useState(true);

  const handleSidebarType = () => {
    setSidebarType(!sidebarType);
  }

  return (
    <div className="layout-container">
      <SideBar Sidebar_Data={AllRoutesData} sidebarType={sidebarType} handleSidebarType={handleSidebarType} />
      <main
        style={{ width: sidebarType ? "calc(100% - 328px)" : "calc(100% - 80px)", right: sidebarType ? "-328px" : "-80px" }}
        className="relative main-container" >
        <Header />
        {children}
        <Copyright />
      </main>
    </div>
  )
}

export default Layout;