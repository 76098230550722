import { Navigate } from "react-router-dom";

const ProtectedRoute = ({ expectedRoles, children }) => {
    const isAuthorized = true
    const areRolesRequired = !!expectedRoles.length
    const roles = ["admin"];

    const rolesMatch = areRolesRequired ? expectedRoles.some(r => roles.indexOf(r) >= 0) : true
    if (!isAuthorized || !rolesMatch) {
        return <Navigate to="/" replace />
    }

    return children
}

export default ProtectedRoute;