export function verifyOtpvalidation(values, submit = true) {
  let errors = {};

  for (let value in values) {
    if (submit) {
      values[value].touched = true;
    }

    const currentField = values[value];

    if (currentField.required && (currentField.value === '' || !currentField.value) && currentField.touched) {
      errors[value] = currentField.requiredMessage ? currentField.requiredMessage : 'This field is required!';
    }
    if (!errors[value] && currentField.matchWith && currentField.value !== values[currentField.matchWith].value && currentField.touched) {
      errors[value] = currentField.matchWithMessage ? currentField.matchWithMessage : 'Fields values are not equal!';
    }

    if (!errors[value] && currentField.minLength && currentField.value !== '' && currentField.value.length < currentField.minLength && currentField.touched) {
      errors[value] = currentField.minLengthMessage ? currentField.minLengthMessage : `This field must have at least ${currentField.minLength} characters`;
    }

    if (!errors[value] && currentField.maxLength && currentField.value !== '' && currentField.value.length > currentField.maxLength && currentField.touched) {
      errors[value] = currentField.maxLengthMessage ? currentField.maxLengthMessage : `This field must have less than ${currentField.maxLength} characters`;
    }
  }

  return errors;
}

export function Loginvalidation(values, submit = false) {
  let errors = {};

  for (let value in values) {
    if (submit) {
      values[value].touched = true;
    }

    const currentField = values[value];

    if (currentField.required && (currentField.value === '' || !currentField.value) && currentField.touched) {
      errors[value] = currentField.requiredMessage ? currentField.requiredMessage : 'This field is required!';
    }

    if (!errors[value] && currentField.matchWith && currentField.value !== values[currentField.matchWith].value && currentField.touched) {
      errors[value] = currentField.matchWithMessage ? currentField.matchWithMessage : 'Fields values are not equal!';
    }

    if (!errors[value] && currentField.minLength && currentField.value !== '' && currentField.value.length < currentField.minLength && currentField.touched) {
      errors[value] = currentField.minLengthMessage ? currentField.minLengthMessage : `This field must have at least ${currentField.minLength} characters`;
    }

    if (!errors[value] && currentField.maxLength && currentField.value !== '' && currentField.value.length > currentField.maxLength && currentField.touched) {
      errors[value] = currentField.maxLengthMessage ? currentField.maxLengthMessage : `This field must have less than ${currentField.maxLength} characters`;
    }
  }

  return errors;
}

export function complaintLogvalidation(values, submit = false) {
  let errors = {};

  for (let value in values) {
    if (submit) {
      values[value].touched = true;
    }

    const currentField = values[value];

    if (currentField.required && (currentField.value === '' || !currentField.value) && currentField.touched) {
      errors[value] = currentField.requiredMessage ? currentField.requiredMessage : 'This field is required!';
    }
  }

  return errors;
}

export function ForgotPasswordFormValidation(values, submit = false) {
  let errors = {};

  for (let value in values) {
    if (submit) {
      values[value].touched = true;
    }

    const currentField = values[value];

    if (currentField.required && (currentField.value === '' || !currentField.value) && currentField.touched) {
      errors[value] = currentField.requiredMessage ? currentField.requiredMessage : 'This field is required!';
    }
  }

  return errors;
}

export function Tpinvalidation(values, submit = false) {
  let errors = {};

  for (let value in values) {
    if (submit) {
      values[value].touched = true;
    }

    const currentField = values[value];

    if (currentField.required && (currentField.value === '' || !currentField.value) && currentField.touched) {
      errors[value] = currentField.requiredMessage ? currentField.requiredMessage : 'This field is required!';
    }

    if (!errors[value] && currentField.matchWith && currentField.value !== values[currentField.matchWith].value && currentField.touched) {
      errors[value] = currentField.matchWithMessage ? currentField.matchWithMessage : 'Fields values are not equal!';
    }

    if (!errors[value] && currentField.minLength && currentField.value !== '' && currentField.value.length < currentField.minLength && currentField.touched) {
      errors[value] = currentField.minLengthMessage ? currentField.minLengthMessage : `This field must have at least ${currentField.minLength} characters`;
    }

    if (!errors[value] && currentField.maxLength && currentField.value !== '' && currentField.value.length > currentField.maxLength && currentField.touched) {
      errors[value] = currentField.maxLengthMessage ? currentField.maxLengthMessage : `This field must have less than ${currentField.maxLength} characters`;
    }
  }

  return errors;
}
