import CloseRoundedIcon from "@mui/icons-material/CloseRounded";
import Box from "@mui/material/Box";
import Modal from "@mui/material/Modal";
import Typography from "@mui/material/Typography";
import "./detailsmodal.css";

const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: "80%",
  bgcolor: "background.paper",
  borderRadius: "12px",
  //   border: "2px solid #000",
  boxShadow: 24,
  p: 4,
  display: "flex",
  "&:focus": {
    outline: "none",
  },
};

export default function DetailsModal({ modalOpen, modalClose, userDetails }) {

  const handleModalClose = () => {
    modalClose(false);
  };

  return (
    <div>
      <Modal
        open={modalOpen}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box sx={style}>
          <div className="w-1/2 rounded-md flex justify-center items-center">
            <img
              loading="lazy"
              className="h-[500px] w-[800px] rounded-lg"
              src={userDetails.image}
              alt="image"
            ></img>
          </div>
          <div className="user-details w-1/2">
            <Typography id="modal-modal-title" variant="h6">
              Name : {userDetails?.name?.toUpperCase()}
            </Typography>
            <Typography id="modal-modal-title" variant="h6">
              Edge Id : {userDetails.edgeId}
            </Typography>
            <Typography id="modal-modal-title" variant="h6">
              Ticket Number : {userDetails.ticketNumber}
            </Typography>
            <Typography id="modal-modal-title" variant="h6">
              Email : {userDetails.email}
            </Typography>
            <Typography id="modal-modal-title" variant="h6">
              Mobile : {userDetails.mobile}
            </Typography>
            <Typography id="modal-modal-description" variant="h6">
              Description :
            </Typography>
            <div className='max-h-[300px] w-full overflow-y-auto break-all'>
              {userDetails.description}
            </div>
          </div>
          <div
            className="detail-modal-close"
            onClick={() => handleModalClose()}
          >
            <span className="modal-close cursor-pointer">
              <CloseRoundedIcon />
            </span>
          </div>
        </Box>
      </Modal>
    </div>
  );
}
