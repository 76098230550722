import VisibilityIcon from "@mui/icons-material/Visibility";
import VisibilityOffIcon from "@mui/icons-material/VisibilityOff";
import { Box } from "@mui/material";
import Grid from "@mui/material/Unstable_Grid2";
import { useContext, useState } from "react";
import CustomButton from "../../../components/common/customButton/customButton";
import UseForm from "../../../hooks/useForm";
import AuthService from "../../../services/auth_service";
import { Constants } from "../../../stateManagement/constants";
import { Context } from "../../../stateManagement/context";
import { Loginvalidation } from "../../../validation/validation";
import "./loginForm.css";

const initialState = {
  phone: {
    value: "",
    required: true
  },
  password: {
    value: "",
    required: true,
    minLength: 4,
    minLengthMessage: 'At least 4 characters long!',
    regexp: /^[a-zA-Z0-9$&+,:;=?@#|'<>.^*()%!-]*$/
  },
};

const buttonStyle = {
  width: "calc(100% - 60px)",
  marginLeft: "30px",
  marginRight: "30px",
  marginTop: "12px",
  marginBottom: "30px",
  lineHeight: "20px",
  letterSpacing: "4px",
};

const LoginForm = () => {
  const [inputType, setInputType] = useState("password");
  const [state, contextDispatch] = useContext(Context);
  const [pending, setPending] = useState("");

  const togglePassword = () => {
    if (inputType === "password") {
      setInputType("text");
      return;
    }
    setInputType("password");
  };

  const submitForm = (e) => {
    if (Object.keys(errors).length === 0 && values.phone.value === "EDGECARE") {
      setPending("loading");
      AuthService.login({
        userId: values.phone.value,
        password: values.password.value,
      })
        .then((res) => {
          setPending("");
          contextDispatch({
            type: Constants.SHOW_SNACKBAR,
            payload: {
              isOpen: true,
              severity: "success",
              message: res?.message,
            },
          });
          localStorage.clear();
          localStorage.setItem("userId", values.phone.value);
          contextDispatch({
            type: Constants.HANDLE_LOGIN,
            payload: {
              showForgotPasswordForm: false,
              showOTPForm: true,
              showLoginForm: false,
              forgotOtpVerifyForm: false,
              setPasswordForm: false,
            },
          });
        })
        .catch((err) => {
          setPending("");
          contextDispatch({
            type: Constants.SHOW_SNACKBAR,
            payload: {
              isOpen: true,
              severity: "error",
              message: err?.response?.data?.message,
            },
          });
        });
    } else {
      contextDispatch({
        type: Constants.SHOW_SNACKBAR,
        payload: {
          isOpen: true,
          severity: "error",
          message: "Enter Valid Credential",
        },
      });
    }
  };

  const handleForgotPassword = () => {
    contextDispatch({
      type: Constants.HANDLE_LOGIN,
      payload: {
        showForgotPasswordForm: true,
        showOTPForm: false,
        showLoginForm: false,
        forgotOtpVerifyForm: false,
        setPasswordForm: false,
      },
    });
  };

  const { handleChange, values, handleSubmit, errors } = UseForm(submitForm, Loginvalidation, initialState);

  return (
    <Grid xs={12} md={4} className="flex justify-center">
      <Box className="login-form">
        <div className="login-form-header">
          <h1 className="form-heading">{process.env.REACT_APP_LOGIN_TITLE}</h1>
        </div>
        <form autoComplete="off" onSubmit={handleSubmit} className="h-full flex flex-col justify-between">
          <Grid container spacing={2} justifyContent="center" className="h-full">
            <Grid
              item
              xs={12}
              md={10}
              sx={{
                flexDirection: "column",
                display: "flex",
                rowGap: "13px",
                marginTop: "10px",
              }}
            >
              <label className="labels">User ID</label>
              <div>
                <input name="phone" value={values.phone.value} onChange={handleChange} className="py-2 px-4 focus:outline-none inputfield" placeholder="Enter User ID" />
                {errors.phone && <p className="absolute errorText">{errors.phone}</p>}
              </div>
            </Grid>
            <Grid
              item
              xs={12}
              md={10}
              sx={{
                flexDirection: "column",
                display: "flex",
                rowGap: "13px",
                marginTop: "10px",
              }}
            >
              <label className="labels">Password</label>
              <div className="relative w-full">
                <div className="absolute inset-y-0 right-0 flex items-center px-2">
                  <div className=" text-sm text-gray-600 font-mono cursor-pointer js-password-label" onClick={togglePassword}>
                    {inputType === "password" ? <VisibilityOffIcon /> : <VisibilityIcon />}
                  </div>
                </div>
                <input name="password" value={values.password.value} onChange={handleChange} className="py-2 px-4 focus:outline-none inputfield" type={inputType} placeholder="Enter Password" />
                {errors.password && <p className="absolute errorText">{errors.password}</p>}
              </div>
            </Grid>
            {/* </Grid> */}
            {/* <Grid container justifyContent="flex-end"> */}
            <Grid item xs={12} md={10}>
              <p className="link-style" onClick={() => handleForgotPassword()}>
                Forgot password
              </p>
            </Grid>
            {/* </Grid> */}
            {/* <Grid container justifyContent="center"> */}
            <Grid item md={12} xs={12}>
              <CustomButton variant={pending}
                style={buttonStyle}
                // handleClick={handleLogin}
                label={"Login"}
              />
            </Grid>
          </Grid>
        </form>
      </Box>
    </Grid>
  );
};

export default LoginForm;
