import { ReactComponent as Dashboard } from "../assets/svg/nav/dashboard.svg";
import { ReactComponent as FundLoad } from "../assets/svg/nav/fundLoad.svg";
import { ReactComponent as MyLedger } from "../assets/svg/nav/myLedger.svg";

const appRoutes = {
  LOGIN: "/",
  DASHBOARD: "/dashboard",
  COMPLAINT: "/complaint",
  RESOLVE: "/resolved",
};

const AllRoutesData = [
  {
    title: "Dashboard",
    path: "/dashboard",
    icon: <Dashboard />,
    cName: "active",
    roles: ["DT", "SD", "RT"],
    navId: 1,
  },
  {
    title: "Complaints",
    path: "/complaint",
    icon: <FundLoad />,
    cName: "active",
    roles: ["DT", "SD", "RT"],
    navId: 2,
  },
  {
    title: "Resolved",
    path: "/resolved",
    icon: <MyLedger />,
    cName: "active",
    roles: ["DT", "SD", "RT"],
    navId: 3,
  },
];

export { appRoutes, AllRoutesData };

