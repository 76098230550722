import api from './api';

const token = localStorage.getItem('user');

console.log(JSON.parse(token)?.accessToken);

const Change_Password = (data) => api.post(`auth/resetpassword`, data);

const Login_Set_New_Password = (data) => api.post(`/auth/setnewpassword`, data);

const Forget_Password_Request = (data) => api.post(`auth/otp/request`, data);

const Forgot_Password_OTP_Verify = (data) => api.post(`/auth/verify`, data);

const List_Complaint = (data) => api.post(`/complaint/list`, data);

const Resolve_Complaint = (data) => api.post(`/complaint/resolve`, data);

const GetProfile = () => api.get(`/user/profile/get`);

const To_Resolve = (data) => api.post(`/complaint/resolve`, data);

const Dashboard = (data) => api.get(`/complaint/getcount`);

const customerCareService = {
  Change_Password,
  Login_Set_New_Password,
  Forgot_Password_OTP_Verify,
  Forget_Password_Request,
  List_Complaint,
  Resolve_Complaint,
  GetProfile,
  To_Resolve,
  Dashboard
};

export default customerCareService;
