import * as React from 'react';
import Checkbox from '@mui/material/Checkbox';

function CustomCheckBox({ handleChange, id, selected }) {
    // const [checked, setChecked] = React.useState(false);

    // const handleChange = (event) => {
    //     setChecked(event.target.checked);
    // };

    const styles = {
        // backgroundColor: '#112467',
        '&.Mui-checked': {
            color: '#112467',
        },
        '&$disabled': {
            backgroundColor: '#112467',
        },
    }

    return (
        <Checkbox sx={styles}
            checked={selected === id ? true : false}
            disabled={selected === id ? true : false}
            onChange={handleChange}
            inputProps={{ 'aria-label': 'controlled' }}
        />
    );
}

export default CustomCheckBox;
