import PersonIcon from "@mui/icons-material/Person";
import { Button, Grid } from "@mui/material";
import Menu from "@mui/material/Menu";
import { useContext, useEffect, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import { ReactComponent as RightArrow } from "../../assets/svg/down-arrow.svg";
import "./header.css";
import UserCard from "./userCard/userCard";
import { styled, alpha } from "@mui/material/styles";
import { Context } from '../../stateManagement/context';
import { Constants } from '../../stateManagement/constants';
import customerCareService from '../../services/customer_care_services';

const MenuStyling = {
  "&	.MuiMenu-paper": {
    marginTop: "2px",
    width: "300px",
  },
  "&	.MuiMenu-list": {
    padding: "0px",
  },
};

function Header() {
  const [data, setData] = useState("");
  const [anchorEl, setAnchorEl] = useState(null);
  const [state, contextDispatch] = useContext(Context);
  const [currentTitle, setCurrentTitle] = useState("");
  const location = useLocation();
  const open = Boolean(anchorEl);

  const StyledMenu = styled((props) => (
    <Menu
      elevation={0}
      anchorOrigin={{
        vertical: "bottom",
        horizontal: "right",
      }}
      transformOrigin={{
        vertical: 0,
        horizontal: 310,
      }}
      {...props}
    />
  ))(({ theme }) => ({
    "& .MuiPaper-root": {
      borderRadius: 6,
      marginTop: theme.spacing(1),
      minWidth: 180,
      color: theme.palette.mode === "light" ? "rgb(55, 65, 81)" : theme.palette.grey[300],
      boxShadow: "rgb(255, 255, 255) 0px 0px 0px 0px, rgba(0, 0, 0, 0.05) 0px 0px 0px 1px, rgba(0, 0, 0, 0.1) 0px 10px 15px -3px, rgba(0, 0, 0, 0.05) 0px 4px 6px -2px",
      "& .MuiMenu-list": {
        padding: "4px 0",
      },
      "& .MuiMenuItem-root": {
        "& .MuiSvgIcon-root": {
          fontSize: 18,
          color: theme.palette.text.secondary,
          marginRight: theme.spacing(1.5),
        },
        "&:active": {
          backgroundColor: alpha(theme.palette.primary.main, theme.palette.action.selectedOpacity),
        },
      },
    },
  }));


  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };


  const titles = [
    { path: "/dashboard", title: "Dashboard" },
    { path: "/complaint", title: "Complaint", },
    { path: "/resolved", title: "Resolved" },
  ];

  const navigate = useNavigate();

  const handleLogOut = () => {
    setAnchorEl(null);
    contextDispatch({
      type: Constants.HANDLE_LOGIN,
      payload: {
        showForgotPasswordForm: false,
        showOTPForm: false,
        showLoginForm: true,
        forgotOtpVerifyForm: false,
        setPasswordForm: false,
      },
    });
    localStorage.setItem("user", null)
    setTimeout(function () {
      navigate("/", { replace: true });
    }, 1000);
  };


  useEffect(() => {
    let mounted = true;
    customerCareService.GetProfile("")
      .then(({ data }) => {
        if (mounted) {
          setData(data?.data);
          contextDispatch({ type: Constants.USER_ID, payload: data?.data?.edgeId?.slice(0, 2) })
        }
      })
      .catch((err) => {
      });

    const path = location.pathname.substring();
    const pathTitle = titles.find((item) => item.path === path);

    document.title = pathTitle.title; // document title

    setCurrentTitle(pathTitle.title);

    return () => (mounted = false);
  }, [state[0]?.callApi, location.pathname]);

  return (
    <Grid container spacing={0} padding="20px">
      <Grid item xs={12} md={12}>
        <div className="header flex w-full">
          <div className="flex justify-start items-center h-12 w-2/5">
            <div className="text-white w-full">
              <span className="select-none head-titles">{currentTitle}</span>
            </div>
          </div>
          <div className="flex h-12 w-3/5 justify-end">
            <Button
              id="customized-button"
              aria-controls={open ? "customized-menu" : undefined}
              aria-haspopup="true"
              aria-expanded={open ? "true" : undefined}
              variant="contained"
              disableElevation
              onClick={handleClick}
              sx={{
                backgroundColor: "#fff",
                color: "#80808F",
                borderRadius: "8px",
                padding: "0.5rem 1rem",
                textTransform: "none",
                "&:hover": {
                  backgroundColor: "#fff",
                },
              }}
              endIcon={<RightArrow />}
              startIcon={<PersonIcon />}
            >
              {data.firstName ? data.firstName.charAt(0).toUpperCase() + data.firstName.slice(1) + " " + data.lastName.charAt(0).toUpperCase() + data.lastName.slice(1) : "loading..."}
            </Button>
            <StyledMenu
              id="customized-menu"
              MenuListProps={{
                "aria-labelledby": "customized-button",
              }}
              anchorEl={anchorEl}
              open={open}
              onClose={handleClose}
            >
              <UserCard
                setAnchorEl={setAnchorEl}
                handleLogOut={handleLogOut}
                data={data}
              />
            </StyledMenu>
          </div>
        </div>
      </Grid>
    </Grid>
  );
}
export default Header;
