import VisibilityIcon from "@mui/icons-material/Visibility";
import VisibilityOffIcon from "@mui/icons-material/VisibilityOff";
import { Box } from "@mui/material";
import Grid from "@mui/material/Unstable_Grid2";
import { useCallback, useContext, useState } from "react";
import CustomButton from "../../../../components/common/customButton/customButton";
import customerCareService from '../../../../services/customer_care_services';
import { Constants } from "../../../../stateManagement/constants";
import { Context } from "../../../../stateManagement/context";
import { Loginvalidation } from "../../../../validation/validation";
import "./setPasswordForm.css";

const initialState = {
  newPassword: {
    value: "",
    required: true,
    minLength: 4,
    minLengthMessage: 'At least 4 characters long!',
    regexp: /^[a-zA-Z0-9$&+,:;=?@#|'<>.^*()%!-]*$/
  },
  confirmPassword: {
    value: '',
    matchWith: "newPassword",
    minLength: 4,
    minLengthMessage: 'At least 4 characters long!',
    matchWithMessage: 'Values must be equal!',
    required: true,
    regexp: /^[a-zA-Z0-9$&+,:;=?@#|'<>.^*()%!-]*$/,
  },
};

const buttonStyle = {
  width: "calc(100% - 60px)",
  margin: "30px",
  lineHeight: "20px",
  letterSpacing: "4px",
};

const SetPasswordForm = () => {
  const [errors, setErrors] = useState({});
  const [newPinType, setNewPinType] = useState("password");
  const [confirmPinType, setConfirmPinType] = useState("password");
  const [values, setValues] = useState(initialState);
  const [state, contextDispatch] = useContext(Context);
  const [pending, setPending] = useState("");

  const togglePassword = (type) => {
    if (type === "newPin") {
      if (newPinType === "password")
        setNewPinType("text");
      else
        setNewPinType("password");
    }
    if (type === "confirmPin") {
      if (confirmPinType === "password")
        setConfirmPinType("text");
      else
        setConfirmPinType("password");
    }

  };

  const setDataAndErrors = useCallback((data) => {
    setValues(data);

    const errors = Loginvalidation(data);

    setErrors(errors);
  }, []);

  const handleChange = useCallback(
    (e) => {
      let updatedData;
      updatedData = {
        ...values,
        [e.target.name]: {
          ...values[e.target.name],
          value: e.target.value,
          touched: true,
        },
      };
      setDataAndErrors(updatedData);
    },
    [setDataAndErrors, values]
  );

  const handleSubmit = (e) => {
    e.preventDefault();
    let formErrors = Loginvalidation(values, true);
    setErrors(formErrors);
    if (Object.keys(formErrors).length === 0) {
      setPending("loading");
      customerCareService
        .Login_Set_New_Password({
          userId: state[0].userId,
          password: values.confirmPassword.value,
        })
        .then((res) => {
          setPending("");
          contextDispatch({
            type: Constants.HANDLE_LOGIN,
            payload: {
              showForgotPasswordForm: false,
              showOTPForm: false,
              showLoginForm: true,
              forgotOtpVerifyForm: false,
              setPasswordForm: false,
            },
          });
          contextDispatch({
            type: Constants.SHOW_SNACKBAR,
            payload: {
              isOpen: true,
              severity: "success",
              message: res?.data?.message,
            },
          });
        })
        .catch((err) => {
          setPending("");
          contextDispatch({
            type: Constants.SHOW_SNACKBAR,
            payload: {
              isOpen: true,
              severity: "error",
              message: err?.response?.data?.message,
            },
          });
        });
    }
  };

  return (
    <Grid xs={12} md={4} className="flex justify-center">
      <Box className="login-form">
        <div className="login-form-header">
          <h1 className="form-heading">{process.env.REACT_APP_LOGIN_TITLE}</h1>
        </div>
        <form autoComplete="off" onSubmit={handleSubmit}>
          {/* <h1 className="setpassword-password-title">Set New Password</h1> */}
          <Grid container spacing={2} justifyContent="center">
            <Grid
              item
              xs={12}
              md={10}
              sx={{
                flexDirection: "column",
                display: "flex",
                rowGap: "13px",
                marginTop: "10px",
              }}
            >
              <label className="labels">Set New Password</label>
              <div>
                <input
                  name="newPassword"
                  value={values.newPassword.value}
                  onChange={handleChange}
                  type={newPinType}
                  className="py-2 px-4 focus:outline-none inputfield"
                  placeholder="Enter New Password"
                />
                {errors.newPassword && (
                  <p className="absolute errorText">{errors.newPassword}</p>
                )}
                <span className="eye-password  text-gray-600" onClick={() => togglePassword("newPin")}>
                  {newPinType === "password" ? <VisibilityOffIcon /> : <VisibilityIcon />}
                </span>
              </div>
            </Grid>
            <Grid
              item
              xs={12}
              md={10}
              sx={{
                flexDirection: "column",
                display: "flex",
                rowGap: "13px",
                marginTop: "10px",
              }}
            >
              <label className="labels">Confirm New Password</label>
              <div className="relative w-full">
                <input
                  name="confirmPassword"
                  value={values.confirmPassword.value}
                  onChange={handleChange}
                  className="py-2 px-4 focus:outline-none inputfield"
                  type={confirmPinType}
                  placeholder="Confirm  Password"
                />
                {errors.confirmPassword && (
                  <p className="absolute errorText">{errors.confirmPassword}</p>
                )}
                <span className="eye-password  text-gray-600" onClick={() => togglePassword("confirmPin")}>
                  {confirmPinType === "password" ? <VisibilityOffIcon /> : <VisibilityIcon />}
                </span>
              </div>
            </Grid>
          </Grid>
          <Grid container justifyContent="center">
            <Grid item md={12} xs={12}>
              <CustomButton
                variant={pending}
                style={buttonStyle}
                // handleClick={handleLogin}
                label={"Set Password"}
              />
            </Grid>
          </Grid>
        </form>
      </Box>
    </Grid>
  );
};

export default SetPasswordForm;
