const ComplaintLogList = (table_Data, table_Head) => {
  let tempObj = {};
  const tabledata = [];
  table_Data &&
    table_Data?.map((tdata, i) => {
      for (const dataproperty in tdata) {
        table_Head.map((thead) => {
          if (dataproperty === thead.id) {
            tempObj = { ...tempObj, [dataproperty]: tdata[dataproperty] };
          }
          if (dataproperty === "user") {
            tempObj = {
              ...tempObj,
              name:
                tdata[dataproperty]?.firstName +
                " " +
                tdata[dataproperty]?.lastName,
              email: tdata[dataproperty]?.email,
            };
          }
        });
      }
      tabledata.push({
        ...tempObj,
        id: tdata.id,
        image: tdata.file,
        edgeId: tdata.edgeId,
        mobile:tdata.mobile
      });
    });
  return { tabledata, table_Head };
};

const ResolveComplaintList = (table_Data, table_Head) => {
  let tempObj = {};
  const tabledata = [];
  table_Data &&
    table_Data?.map((tdata, i) => {
      for (const dataproperty in tdata) {
        table_Head.map((thead) => {
          if (dataproperty === thead.id) {
            tempObj = { ...tempObj, [dataproperty]: tdata[dataproperty] };
          }
          if (dataproperty === "isResolved") {
            tempObj = {
              ...tempObj,
              isResolved: tdata[dataproperty] ? "YES" : "NO",
            };
          }
        });
      }
      tabledata.push({ ...tempObj, id: i + 1 });
    });
  return { tabledata, table_Head };
};

export { ComplaintLogList, ResolveComplaintList };
