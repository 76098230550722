import { Box } from "@mui/material";
import Grid from "@mui/material/Unstable_Grid2";
import { useCallback, useContext, useState } from "react";
import CustomButton from "../../../../components/common/customButton/customButton";
import customerCareService from '../../../../services/customer_care_services';
import { Constants } from "../../../../stateManagement/constants";
import { Context } from "../../../../stateManagement/context";
import { ForgotPasswordFormValidation } from "../../../../validation/validation";
import "./forgotPasswordForm.css";

const initialState = {
  phone: { value: "", required: true },
};

const buttonStyle = {
  width: "calc(80% - 60px)",
  marginLeft: "30px",
  marginRight: "30px",
  marginTop: "20px",
  marginBottom: "30px",
  lineHeight: "20px",
  letterSpacing: "4px",
};

const ForgotPasswordForm = ({ submitForm }) => {
  const [errors, setErrors] = useState({});
  const [values, setValues] = useState(initialState);
  const [state, contextDispatch] = useContext(Context);
  const [pending, setPending] = useState("");

  const setDataAndErrors = useCallback((data) => {
    setValues(data);

    const errors = ForgotPasswordFormValidation(data);

    setErrors(errors);
  }, []);

  const handleChange = useCallback(
    (e) => {
      let updatedData;
      updatedData = {
        ...values,
        [e.target.name]: {
          ...values[e.target.name],
          value: e.target.value,
          touched: true,
        },
      };
      setDataAndErrors(updatedData);
    },
    [setDataAndErrors, values]
  );

  const handleSubmit = (e) => {
    e.preventDefault();
    let formErrors = ForgotPasswordFormValidation(values, true);
    setErrors(formErrors);
    if (Object.keys(formErrors).length === 0) {
      setPending("loading");
      customerCareService
        .Forget_Password_Request({ key: values.phone.value })
        .then((res) => {
          setPending("");
          contextDispatch({
            type: Constants.SHOW_SNACKBAR,
            payload: {
              isOpen: true,
              severity: "success",
              message: res?.data?.message,
            },
          });
          contextDispatch({
            type: Constants.HANDLE_LOGIN,
            payload: {
              showForgotPasswordForm: false,
              showOTPForm: false,
              showLoginForm: false,
              forgotOtpVerifyForm: true,
              setPasswordForm: false,
            },
          });
          contextDispatch({
            type: Constants.UPDATE_USERID,
            payload: res?.data?.data,
          });
        })
        .catch((err) => {
          setPending("");
          contextDispatch({
            type: Constants.SHOW_SNACKBAR,
            payload: {
              isOpen: true,
              severity: "error",
              message: err?.response?.data?.message,
            },
          });
        });
    }
  };

  return (
    <Grid xs={12} md={4} className="flex justify-center">
      <Box className="forgot-password-form">
        <div className="login-form-header">
          <h1 className="form-heading">{process.env.REACT_APP_LOGIN_TITLE}</h1>
        </div>
        <form autoComplete="off" onSubmit={handleSubmit} className="w-full">
          <Grid container spacing={2} justifyContent="center">
            <Grid
              item
              xs={12}
              md={12}
              sx={{
                flexDirection: "column",
                display: "flex",
                rowGap: "13px",
                marginTop: "20px",
                margin: "1rem",
                padding: "10px 30px"
              }}
            >
              <label className="labels">Phone Number / Login ID</label>
              <div>
                <input
                  name="phone"
                  value={values.phone.value}
                  onChange={handleChange}
                  className="py-2 px-4 focus:outline-none inputfield"
                  placeholder="Phone number / ID"
                />
                {errors.phone && (
                  <p className="absolute errorText">{errors.phone}</p>
                )}
              </div>
            </Grid>
          </Grid>
          <Grid container justifyContent="center">
            <Grid item md={12} xs={12} display="flex" justifyContent="center">
              <CustomButton
                variant={pending}
                style={buttonStyle}
                // handleClick={handleLogin}
                label={"Request OTP"}
              />
            </Grid>
          </Grid>
        </form>
      </Box>
    </Grid>
  );
};

export default ForgotPasswordForm;
