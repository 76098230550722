
import './copyright.css'

export default function Copyright() {

  return (
    <div className="copyright">
      <div className="copyright-text text-[#3A416F]">{process.env.REACT_APP_COPYRIGHT_LINE_1}</div>
    </div>
  );
}
