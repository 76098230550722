import Box from "@mui/material/Box";
import Modal from "@mui/material/Modal";
import { useContext, useEffect } from "react";
import fail from "../../../assets/gif/fail.gif";
import success from "../../../assets/gif/success.gif";
import { Constants } from "../../../stateManagement/constants";
import { Context } from "../../../stateManagement/context";
import "./popUp.css";

const Popup = () => {
  const [state, contextDispatch] = useContext(Context);

  useEffect(() => {
    let timer = setTimeout(() => {
      contextDispatch({
        type: Constants.SHOW_POPUP,
        payload: {
          type: Constants.SHOW_POPUP,
          showLotti: false,
          lottiSeverity: "",
        },
      });
    }, 3000);
    return () => {
      clearTimeout(timer);
    };
  }, [state[0]?.Popup?.showLotti]);

  const style = {
    position: "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    width: 400,
    // height:350,
    bgcolor: "background.paper",
    border: "none",
    borderRadius: "48px",
    boxShadow: 24,
    background:
      state[0]?.Popup?.lottiSeverity === "success"
        ? "#17AD37"
        : "linear-gradient(135deg, #EC7D2D 0%, #EB2D2D 100%)",
    p: 4,
    outline: "none",
  };

  return (
    <div>
      <Modal
        disableScrollLock
        sx={{ outline: "none" }}
        open={state[0]?.Popup?.showLotti ?? false}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box sx={style}>
          <h1 className="mb-5 text-center response-modal-heading">
            {state[0]?.Popup?.lottiSeverity === "success"
              ? "Transaction Success"
              : "Transaction Failed"}
          </h1>
          <img
            className="w-65 h-56 flex m-auto"
            alt="gif"
            src={state[0]?.Popup?.lottiSeverity === "success" ? success : fail}
          ></img>
          <p className="mt-5 text-center response-modal-paragraph">
            Thank you for chosing Ecaps Services
          </p>
        </Box>
      </Modal>
    </div>
  );
};

export default Popup;
