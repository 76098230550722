import { Box } from "@mui/material";
import Grid from "@mui/material/Unstable_Grid2";
import { useCallback, useContext, useEffect, useState } from "react";
import { useNavigate } from 'react-router-dom';
import CustomButton from "../../../components/common/customButton/customButton";
import AuthService from "../../../services/auth_service";
import { Constants } from "../../../stateManagement/constants";
import { Context } from "../../../stateManagement/context";
import { verifyOtpvalidation } from "../../../validation/validation";
import "./otpForm.css";

const initialState = {
  otpInput: {
    value: "", required: true,
    regexp: /^[0-9]*$/,
    minLength: 6,
    minLengthMessage: 'At least 6 characters long!',
    maxLength: 6,
    maxLengthMessage: 'Too many characters!'
  },
};


const buttonStyle = {
  width: "calc(100% - 60px)",
  marginLeft: "30px",
  marginRight: "30px",
  marginTop: "12px",
  marginBottom: "8px",
  lineHeight: "20px",
  letterSpacing: "4px",
};


const OtpForm = () => {
  const navigate = useNavigate();
  const [errors, setErrors] = useState({});
  const [values, setValues] = useState(initialState);
  const [counter, setCounter] = useState(60 * 3);
  const [state, contextDispatch] = useContext(Context);
  const [pending, setPending] = useState("");

  useEffect(() => {
    if (counter > 0) {
      const timer = setInterval(
        () => setCounter((counter) => counter - 1),
        1000
      );
      return () => clearInterval(timer);
    }
  }, [counter]);

  const seconds = String(counter % 60).padStart(2, 0);
  const minutes = String(Math.floor(counter / 60)).padStart(2, 0);

  const handleSubmit = (e) => {
    e.preventDefault();
    let formErrors = verifyOtpvalidation(values, true);
    setErrors(formErrors);
    if (Object.keys(formErrors).length === 0) {
      setPending("loading");
      AuthService.otplogin({
        userId: localStorage.getItem("userId"),
        otp: values?.otpInput.value,
      })
        .then((data) => {
          setPending("");
          contextDispatch({
            type: Constants.SHOW_SNACKBAR,
            payload: { isOpen: true, severity: "success", message: data?.message },
          });
          if (data.data.accessToken) navigate("/dashboard");
          return data;
        })
        .catch((err) => {
          setPending("");
          contextDispatch({
            type: Constants.SHOW_SNACKBAR,
            payload: { isOpen: true, severity: "error", message: err?.response?.data?.message },
          });
        });
    }
  };

  const handleResendOtp = () => setCounter(60 * 3);

  const setDataAndErrors = useCallback((data) => {
    setValues(data);

    const errors = verifyOtpvalidation(data);

    setErrors(errors);
  }, []);

  const handleChange = useCallback(
    (e) => {
      let updatedData;
      if (values[e.target.name]?.regexp) {
        updatedData = {
          ...values,
          [e.target.name]: {
            ...values[e.target.name],
            value: values[e.target.name]?.regexp.test(e.target.value) ? e.target.value : values["otpInput"]?.value,
            touched: true,
          },
        };
      }

      setDataAndErrors(updatedData);
    },
    [setDataAndErrors, values]
  );

  return (
    <Grid xs={12} md={4} className="flex justify-center">
      <Box className="login-form">
        <div className="login-form-header">
          <h1 className="form-heading">{process.env.REACT_APP_LOGIN_TITLE}</h1>
        </div>
        <form onSubmit={handleSubmit} autoComplete="off" className="h-full flex flex-col justify-between">
          <Grid container spacing={2} justifyContent="center">
            <Grid
              item
              xs={12}
              md={10}
              sx={{
                flexDirection: "column",
                display: "flex",
                rowGap: "13px",
                marginTop: "10px",
              }}
            >
              <label className="labels">Enter OTP Received</label>
              <input
                name="otpInput"
                onChange={handleChange}
                value={values.otpInput.value}
                className="py-2 px-4 focus:outline-none otpfield"
                type="text"
                maxLength={6}
                placeholder="Enter OTP Here"
              />
              {errors.otpInput && (
                <p className="absolute top-36 pt-2 errorText">{errors.otpInput}</p>
              )}
            </Grid>
          </Grid>
          {/* <Grid container justifyContent="center"> */}
          <Grid item xs={12} md={12}>
            <div className="text-white otp-paragraph mt-4">
              New browser detected! An OTP has been sent to your registered
              mobile number. Enter that 6 digit OTP to complete your login
              process.
            </div>
            <div className="otp-min text-white">
              {" "}
              {counter <= 0
                ? "OTP has expired."
                : `Request OTP in ${minutes}:${seconds} min`}
            </div>
          </Grid>
          <Grid
            item
            md={12}
            xs={12}
            padding="0px 8px"
            marginBottom="30px"
            position="relative"
          >
            {/* disable={formData.button} button disable  */}
            <CustomButton variant={pending} style={buttonStyle} label={"Verify"} />
            <p className="otpform-resendotp" onClick={handleResendOtp}>
              {counter <= 0 && "Resend OTP"}
            </p>
            {/* </Grid> */}
          </Grid>
        </form>
      </Box>
    </Grid>
  );
};

export default OtpForm;
