import api from './api';
import TokenService from './token_service';

const login = (data) => {
  return api.post(`/auth/login`, data).then((response) => {
    return response.data;
  });
};

const otplogin = (data) => {
  return api.post(`/auth/verify`, data).then((response) => {
    if (response?.data?.data?.accessToken) {
      TokenService.setUser(response.data.data);
    }
    return response.data;
  });
};

const logout = () => {
  TokenService.removeUser();
};

const getCurrentUser = () => {
  return JSON.parse(localStorage.getItem('user'));
};

const authService = {
  login,
  otplogin,
  logout,
  getCurrentUser,
};

export default authService;
