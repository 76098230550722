import Grid from "@mui/material/Unstable_Grid2";
import { useContext } from "react";
import { Context } from "../../stateManagement/context";
import ForgotOtpVerifyForm from "./forgotPassword/forgotOtpVerifyForm/forgotOtpVerifyForm";
import ForgotPasswordForm from "./forgotPassword/forgotPasswordForm/forgotPasswordForm";
import SetPasswordForm from "./forgotPassword/setPasswordForm/setPasswordForm";
import "./login.css";
import LoginForm from "./loginForm/loginForm";
import OtpForm from "./otpForm/otpForm";

export default function Login() {
  const [state, contextDispatch] = useContext(Context);
  document.title = "Customer Care";
  const ForgotPasswordStepperFunction = () => {
    switch (true) {
      case state[0]?.login.showLoginForm:
        return <LoginForm />;
      case state[0]?.login.showOTPForm:
        return <OtpForm />;
      case state[0]?.login.showForgotPasswordForm:
        return <ForgotPasswordForm />;
      case state[0]?.login.forgotOtpVerifyForm:
        return <ForgotOtpVerifyForm />;
      case state[0]?.login.setPasswordForm:
        return <SetPasswordForm />;
      default:
        return <LoginForm />;
    }
  };
  return (
    <Grid container spacing={0} className="container-login">
      <Grid xs={6} md={6}>
        <img src={process.env.REACT_APP_LOGIN_IMAGE_2} className="about-us-svg" alt="login-image-2"></img>
      </Grid>
      <Grid xs={6} md={6}>
        {/* <h2 className="about-text">About Us</h2> */}
      </Grid>

      <Grid xs={2} md={2}>
        <img className="main-svg-login" src={process.env.REACT_APP_LOGIN_IMAGE_1} alt="login-image-2"></img>
      </Grid>

      <Grid xs={12} justifyContent="center" md={6}>
        <h2 className="main-heading">Recharge & Pay Bills</h2>
        <p className="main-paragraph">
          Simplest and fastest way to recharge and pay bills for all your
          mobile,
          <br />
          DTH and other utilities at a single location
        </p>
      </Grid>
      {ForgotPasswordStepperFunction()}
      <Grid xs={12} justifyContent="center" md={12} rowGap={2} margin="2rem 0px" className="flex flex-col text-sm">
        <div className="copyright-text text-white">{process.env.REACT_APP_COPYRIGHT_LINE_1}</div>
        <div className='copyright-text text-white'>{process.env.REACT_APP_COPYRIGHT_LINE_2}</div>
      </Grid>
    </Grid>
  );
}
