import { useEffect, useState } from 'react';
import CustomTable from '../../components/common/customTable/customTable';
import DateFormatter from '../../hooks/dateFormatter';
import { ResolveComplaintList } from '../../hooks/useTableData';
import customerCareService from '../../services/customer_care_services';

const tableHeadResolveComplaint = [
  {
    name: 'Edge Id',
    selector: (row) => row.edgeId,
    sortable: true,
    id: 'edgeId',
  },
  {
    name: 'Ticket Number',
    selector: (row) => row.ticketNumber,
    sortable: true,
    id: 'ticketNumber',
  },
  {
    name: 'Description',
    selector: (row) => row.description,
    sortable: true,
    id: 'description',
  },
  {
    name: 'Created At',
    selector: (row) => row.createdAt,
    sortable: true,
    id: 'createdAt',
    format: (row) => DateFormatter(row.createdAt, 'table'),
  },
];

function ResolveComplaint() {
  const [fetchedTableData, setFetchedTableData] = useState([]);
  const [pending, setPending] = useState(true);

  useEffect(() => {
    customerCareService
      .List_Complaint({ isResolved: true })
      .then((res) => {
        setPending(false);
        setFetchedTableData(res?.data?.data);
      })
      .catch((err) => {
        setPending(false);
        // console.log('err', err);
      });
  }, []);

  const { tabledata, table_Head } = ResolveComplaintList(fetchedTableData, tableHeadResolveComplaint);

  return (
    <>
      <div className='m-5 rounded-xl bg-white'>
        <p className='text-sm text-[#112467] pt-5 font-bold text-[18px] mx-5'>Resolved Log</p>
        <div className='table-container p-5 mb-24'>
          <CustomTable pending={pending} tablehead={table_Head} tabledata={tabledata} />
        </div>
      </div>
    </>
  );
}

export default ResolveComplaint;
