export const Constants = {
  SHOW_SNACKBAR: 'SHOW_SNACKBAR',

  SHOW_POPUP: 'SHOW_POPUP',

  HANDLE_LOGIN:"HANDLE_LOGIN",

  UPDATE_USERID:"UPDATE_USERID",

  USER_ID:"USER_ID",

  CALL_API:"CALL_API"
};
