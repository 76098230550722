import AssessmentIcon from "@mui/icons-material/Assessment";
import KeyboardArrowDownRoundedIcon from "@mui/icons-material/KeyboardArrowDownRounded";
import { useContext, useState } from "react";
import { NavLink } from "react-router-dom";
import { v4 as uuidv4 } from "uuid";
import SidebarControl from "../../../assets/svg/nav/sidebarControl.svg";
import { Constants } from '../../../stateManagement/constants';
import { Context } from '../../../stateManagement/context';
// import Copyright from "../../common/copyright/copyright";
// import Sidebar_Data from "../sidebarData";
import "./sidebaropen.css";

const SidebarOpen = ({ handleSidebarType, Sidebar_Data }) => {

  const [openChild, setOpenChild] = useState("");
  const [state, contextDispatch] = useContext(Context);

  const showSideBar = () => {
    handleSidebarType();
    // setSidebar(!sideBar)
  };

  const showSubnav = (id) =>
    id
      ? openChild === id
        ? setOpenChild("")
        : setOpenChild(id)
      : setOpenChild("");

  return (
      <div style={{ width: "328px" }} className="sidebar">
        <span className="logo"><img src={process.env.REACT_APP_SIDEBAR} alt="sidebar-open" /></span>
        <div className="sidebaropen-openicon">
          <img
            className="cursor-pointer"
            onClick={showSideBar}
            alt="SidebarControl"
            src={SidebarControl}
          />
        </div>
        <div className="sidebaropen-container">
          {Sidebar_Data?.map((item) => (
            <div id="sidebaropen-navlink-container" key={uuidv4()}>
              <NavLink
                className={`${openChild === item?.navId ? "link active" : "link"
                  }`} // Parent link
                activeclassname="active"
                to={item.path}
                onClick={() => {
                  contextDispatch({
                    type: Constants.ACTIVE_TAB_ID,
                    payload: 1,
                  });
                  item.subNav ? showSubnav(item.navId) : showSubnav();
                }}
              >
                <div className="flex items-center justify-between w-full m-2.5">
                  <div className="flex items-center ">
                    <span className="icon">{item.icon}</span>
                    <span className="link_text pl-5">{item.title}</span>
                  </div>
                  {item.subNav ? (
                    openChild === item?.navId ? (
                      <span className="rotate-180">
                        <KeyboardArrowDownRoundedIcon />
                      </span>
                    ) : (
                      <span>
                        <KeyboardArrowDownRoundedIcon />
                      </span>
                    )
                  ) : null}
                </div>
              </NavLink>
              {openChild === item?.navId &&
                item?.subNav
                  ?.filter((sub) => sub?.roles?.includes(state[0]?.userId))
                  .map((item) => {
                    return (
                      <NavLink
                        onClick={() => contextDispatch({
                          type: Constants.ACTIVE_TAB_ID,
                          payload: 1,
                        })} //Child link
                        className={`${openChild === item?.navId ? "link active" : "link"
                          }`} //Need some changes
                        activeclassname="active"
                        to={item.path}
                        key={uuidv4()}
                      >
                        <div className={"flex items-center m-2.5"}>
                          <span>
                            <AssessmentIcon />
                          </span>
                          <span className="link_text">{item.title}</span>
                        </div>
                      </NavLink>
                    );
                  })}
            </div>
          ))}
        </div>
      </div>
  );
};
export default SidebarOpen;
