import VisibilityIcon from "@mui/icons-material/Visibility";
import VisibilityOffIcon from "@mui/icons-material/VisibilityOff";
import Box from '@mui/material/Box';
import Modal from '@mui/material/Modal';
import { useContext, useState } from "react";
import RightArrow from "../../../assets/svg/down-arrow.svg";
import LogOut from "../../../assets/svg/logout.svg";
import UseForm from "../../../hooks/useForm";
import customerCareService from '../../../services/customer_care_services';
import { Constants } from "../../../stateManagement/constants";
import { Context } from "../../../stateManagement/context";
import { Tpinvalidation } from "../../../validation/validation";
import CustomButton from "../../common/customButton/customButton";
import CustomInput from "../../common/customInput/customInput";
import "./usercard.css";

const UserCard = ({ handleLogOut, data, setAnchorEl }) => {
  const [state, contextDispatch] = useContext(Context);
  const [newPinType, setNewPinType] = useState("password");
  const [confirmPinType, setConfirmPinType] = useState("password");
  const [oldPinType, setOldPinType] = useState("password");
  const [open, setOpen] = useState(false);
  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);

  const style = {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: 400,
    bgcolor: 'background.paper',
    border: 'none',
    borderRadius: "1rem",
    boxShadow: 24,
    padding: "30px 30px 40px 30px",
  };

  const buttonStyle = {
    width: "30%",
    lineHeight: "17px",
    letterSpacing: "4px",
  };

  const initialState = {
    oldPassword: {
      value: '', required: true, minLength: 4,
      minLengthMessage: 'At least 4 characters long!',
      regexp: /^[a-zA-Z0-9$&+,:;=?@#|'<>.^*()%!-]*$/,
    },
    newPassword: {
      value: '', required: true, minLength: 4,
      minLengthMessage: 'At least 4 characters long!',
      regexp: /^[a-zA-Z0-9$&+,:;=?@#|'<>.^*()%!-]*$/,
    },
    confirmPassword: { value: '', matchWith: "newPassword", matchWithMessage: 'values must be equal!', required: true, regexp: /^[a-zA-Z0-9$&+,:;=?@#|'<>.^*()%!-]*$/, },
  };

  function submitForm(value) {
    setAnchorEl(null)
    customerCareService.Change_Password({
      oldpassword: values?.oldPassword?.value,
      password: values?.confirmPassword?.value,
    }).then((res) => {
      contextDispatch({
        type: Constants.SHOW_SNACKBAR,
        payload: {
          isOpen: true,
          severity: "success",
          message: res?.data?.message,
        },
      });
    }).catch((err) => {
      console.log(err)
      contextDispatch({
        type: Constants.SHOW_SNACKBAR,
        payload: {
          isOpen: true,
          severity: "success",
          message: err?.response?.data?.message,
        },
      });
    })
  }

  const togglePassword = (type) => {
    if (type === "oldPin") {
      if (oldPinType === "password")
        setOldPinType("text");
      else
        setOldPinType("password");
    }
    if (type === "newPin") {
      if (newPinType === "password")
        setNewPinType("text");
      else
        setNewPinType("password");
    }
    if (type === "confirmPin") {
      if (confirmPinType === "password")
        setConfirmPinType("text");
      else
        setConfirmPinType("password");
    }

  };

  const { handleChange, values, handleSubmit, errors } = UseForm(submitForm, Tpinvalidation, initialState);
  return (
    <div className="usercard-container">
      <div className="pb-2.5">
        <div className="flex gap-6">
          <div className="usercard-logo-container">
            <div>
              <img src={process.env.REACT_APP_LOGIN_IMAGE_1} alt="UserCardLogo"></img>
            </div>
          </div>
          <div className="flex flex-col">
            <span className="usercard-user-type">{data?.userType ? `EDGE - ${data?.userType?.charAt(0).toUpperCase() + data?.userType?.slice(1)}` : "loading...."}</span>
            <span className="usercard-user-id">{data?.edgeId ? data?.edgeId : "loading...."}</span>
          </div>
        </div>
      </div>
      <div className="usercard-change-password" >
        <span onClick={handleOpen}>Change Password</span>
        <img src={RightArrow} alt="RightArrow" style={{ rotate: "270deg" }}></img>
      </div>
      <div className="usercard-logout" onClick={handleLogOut}>
        <span>Logout</span>
        <img src={LogOut} alt="LogOut"></img>
      </div>
      <Modal
        open={open}
        onClose={handleClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box sx={style}>
          <div className="text-center flex justify-center flex-col">
            <h1 className="modal-title">Change password</h1>
            <div className="modal-line"></div>
          </div>
          <form onSubmit={handleSubmit} className="passwordfields" autoComplete="off">
            <div>
              <CustomInput type={oldPinType} name="oldPassword" value={values.oldPassword.value} handleChange={handleChange} placeholder={"Old Password"} />
              {errors.oldPassword && <p className="absolute  errorText">{errors.oldPassword}</p>}
              <span className="eye-password  text-gray-600" onClick={() => togglePassword("oldPin")}>
                {oldPinType === "password" ? <VisibilityOffIcon /> : <VisibilityIcon />}
              </span>
            </div>
            <div>
              <CustomInput type={newPinType} name="newPassword" value={values.newPassword.value} handleChange={handleChange} placeholder={"New Password"} />
              {errors.newPassword && <p className="absolute  errorText">{errors.newPassword}</p>}
              <span className="eye-password  text-gray-600" onClick={() => togglePassword("newPin")}>
                {newPinType === "password" ? <VisibilityOffIcon /> : <VisibilityIcon />}
              </span>
            </div>
            <div>
              <CustomInput type={confirmPinType} name="confirmPassword" value={values.confirmPassword.value} handleChange={handleChange} placeholder={"Confirm Password"} />
              {errors.confirmPassword && <p className="absolute  errorText">{errors.confirmPassword}</p>}
              <span className="eye-password  text-gray-600" onClick={() => togglePassword("confirmPin")}>
                {confirmPinType === "password" ? <VisibilityOffIcon /> : <VisibilityIcon />}
              </span>
            </div>
            <div className="flex justify-center w-full h-12 get-otp-btn-changepassword">
              <CustomButton style={buttonStyle} label={"Submit"} />
            </div>
          </form>
        </Box>
      </Modal>
    </div>
  );
};

export default UserCard;
