import VisibilityIcon from '@mui/icons-material/Visibility';
import { Grid } from '@mui/material';
import { useContext, useEffect, useState } from 'react';
import CustomButton from '../../components/common/customButton/customButton';
import CustomCheckBox from '../../components/common/customCheckBox/customCheckBox';
import CustomInput from '../../components/common/customInput/customInput';
import CustomTable from '../../components/common/customTable/customTable';
import DetailsModal from '../../components/common/detailsModal/detailsModal';
import DateFormatter from '../../hooks/dateFormatter';
import UseForm from '../../hooks/useForm';
import { ComplaintLogList } from '../../hooks/useTableData';
import customerCareService from '../../services/customer_care_services';
import { Constants } from '../../stateManagement/constants';
import { Context } from '../../stateManagement/context';
import { complaintLogvalidation } from '../../validation/validation';
import './complaintlog.css';

const initialState = {
  inputValue: { value: '', required: true },
  // filterType: { value: '', required: true },
};

const buttonStyle = {
  width: '15%',
  // marginTop: "38px",
  lineHeight: '20px',
  letterSpacing: '4px',
  height: '48px',
};

const selectStyle = {
  borderRadius: '12px',
  backgroundColor: 'white',
  boxShadow: '0px 1px 2px rgba(0, 0, 0, 0.25)',
  height: '42px',
  width: '100%',
};

const selectBank = [
  { value: 'customer', label: 'Customer' },
  { value: 'ticket_id', label: 'Ticket ID' },
];

function ComplaintLog() {
  const [fetchedTableData, setFetchedTableData] = useState([]);
  const [state, contextDispatch] = useContext(Context);
  const [pending, setPending] = useState(true);
  const [selected, setSelected] = useState('');
  const [visible, setVisible] = useState(false);
  const [userDetails, setUserDetails] = useState('');

  const tableHeadComplaint = [
    {
      name: 'Edge Id',
      selector: (row) => row.edgeId,
      sortable: true,
      id: 'edgeId',
      // width:"100px !important"
    },
    {
      name: 'Ticket Number',
      selector: (row) => row.ticketNumber,
      sortable: true,
      id: 'ticketNumber',
      width: '150px !important',
    },
    {
      name: 'Description',
      selector: (row) => row.description,
      sortable: true,
      id: 'description',
      width: '400px !important',
    },
    {
      name: 'Created At',
      selector: (row) => row.createdAt,
      sortable: true,
      id: 'createdAt',
      format: (row) => DateFormatter(row.createdAt, 'table'),
      width: '200px !important',
    },
    {
      name: 'Action',
      width: '100px !important',
      cell: (row) => (
        <div className='p-2.5 flex items-center gap-3'>
          <VisibilityIcon className='cursor-pointer' onClick={() => iconClick(row)} />
          <CustomCheckBox
            id={row.id}
            selected={selected}
            handleChange={(e) => {
              setSelected(row.id);
              customerCareService
                .To_Resolve({ id: row.id })
                .then((res) => {
                  setSelected('');
                  contextDispatch({
                    type: Constants.CALL_API,
                    payload: !state[0]?.callApi,
                  });
                  contextDispatch({
                    type: Constants.SHOW_SNACKBAR,
                    payload: {
                      isOpen: true,
                      severity: 'success',
                      message: res?.data?.message,
                    },
                  });
                })
                .catch((err) => {
                  setSelected('');
                  contextDispatch({
                    type: Constants.SHOW_SNACKBAR,
                    payload: {
                      isOpen: true,
                      severity: 'error',
                      message: err?.response?.data?.message,
                    },
                  });
                });
            }}
          />
        </div>
      ),
      // ignoreRowClick: true,
      // allowOverflow: true,
      // button: true,
      allowRowEvents: true,
      // width: '20px !important',
    },
  ];

  const onRowClicked = (row) => {
    console.log('clicked', row);
  };

  const iconClick = (currentRow) => {
    console.log('current', currentRow);
    setUserDetails(currentRow);
    setVisible(true);
  };
  useEffect(() => {
    setPending(true);
    customerCareService
      .List_Complaint({ isResolved: false })
      .then((res) => {
        setPending(false);
        setFetchedTableData(res?.data?.data);
      })
      .catch((err) => {
        setPending(false);
      });
  }, [state[0]?.callApi]);

  function submitForm() {
    setPending(true);
    customerCareService
      .List_Complaint({ key: values.inputValue.value })
      .then((res) => {
        setPending(false);
        setFetchedTableData(res?.data?.data);
      })
      .catch((err) => {
        setPending(false);
        // console.log('err', err);
      });
  }

  const { handleChange, selectChange, values, handleSubmit, errors } = UseForm(submitForm, complaintLogvalidation, initialState);

  const { tabledata, table_Head } = ComplaintLogList(fetchedTableData, tableHeadComplaint);

  return (
    <>
      <div className='relative mx-5'>
        <form onSubmit={handleSubmit} autoComplete='off'>
          <Grid
            container
            justifyContent={'space-between'}
            className='modal-transparency'
            style={{
              width: 'calc(100% - 60px)',
              margin: '-100px 30px 0px 30px',
              position: 'relative',
              padding: '30px 30px 60px 30px',
              borderRadius: '12px',
              color: '#112467',
              fontWeight: 500,
              fontSize: '16px',
            }}
          >
            {/* <Grid item sm={5} className='flex w-full items-center justify-between gap-x-4'>
              <span className='whitespace-nowrap'>Filter By</span>
              <div className='w-3/4'>
                <CustomSelect style={selectStyle} name='filterType' value={values.filterType.value} handleChange={selectChange} data={selectBank} label='Select a type' />
                {errors.filterType && <p className='absolute errorText py-2'>{errors.filterType}</p>}
              </div>
            </Grid> */}
            <Grid item sm={8} className='flex w-full items-center gap-x-4'>
              <span className='whitespace-nowrap'>Edge ID / Ticket Number</span>
              <div className='w-1/2'>
                <CustomInput
                  name='inputValue'
                  value={values.inputValue.value}
                  handleChange={handleChange}
                  placeholder='Enter Edge ID/ Ticket Number'
                />
                {errors.inputValue && <p className='absolute errorText'>{errors.inputValue}</p>}
              </div>
            </Grid>
            {/* <Grid item sm={6} className='flex w-full items-center justify-between gap-x-4'>
            <span className='whitespace-nowrap'>Search By</span>
            <div className='w-3/4'>
              <CustomSelect data={selectBank} />
            </div>
          </Grid> */}
            <div className='complaint-log-btn'>
              <CustomButton style={buttonStyle} label={'Search'} />
            </div>
          </Grid>
        </form>
        <div className='bg-white mt-16 rounded-xl'>
          <p className='text-sm text-[#112467] pt-5 font-bold text-[18px] mx-5'>Complaints Log</p>
          <div className='table-container p-5 mb-24'>
            <CustomTable onRowClicked={onRowClicked} pending={pending} tablehead={table_Head} tabledata={tabledata} />
          </div>
        </div>
        <DetailsModal modalOpen={visible} modalClose={setVisible} userDetails={userDetails} />
      </div>
    </>
  );
}

export default ComplaintLog;
