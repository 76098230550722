import { useReducer, createContext } from "react";
import { Constants } from "./constants";

const Context = createContext();

const initState = [
  {
    SnackBar: { isOpen: false, severity: "", message: "" },
    login: {
      showForgotPasswordForm: false,
      showOTPForm: false,
      showLoginForm: true,
      forgotOtpVerifyForm: false,
      setPasswordForm: false,
    },
    Popup: { showLotti: false, lottiSeverity: "" },
    callApi: false,
    userId: "",
  },
];

function ContextProvider({ children }) {
  const Reducer = (state, action) => {
    console.log("state", state);
    switch (action.type) {
      case Constants.SHOW_SNACKBAR:
        return [{ ...state[0], ["SnackBar"]: action.payload }];
      case Constants.SHOW_POPUP:
        return [{ ...state[0], ["Popup"]: action.payload }];
      case Constants.HANDLE_LOGIN:
        return [{ ...state[0], ["login"]: action.payload }];
      case Constants.USER_CONTAINER:
        return [
          {
            ...state[0],
            ["DMT"]: { ...state[0].DMT, checkRemmitter: action.payload },
          },
        ];
      case Constants.UPDATE_USERID:
        return [{ ...state[0], ["userId"]: action.payload }];
      case Constants.CALL_API:
        return [{ ...state[0], ["callApi"]: action.payload }];
      default:
        return [{ ...state[0] }];
    }
  };

  const [state, contextDispatch] = useReducer(Reducer, initState);

  return <Context.Provider value={[state, contextDispatch]}>{children}</Context.Provider>;
}

export { ContextProvider, Context };
