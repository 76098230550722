import { Suspense } from 'react';
import Loader from '../components/common/loader/loader';
import ErrorBoundary from '../components/errorBoundary/errorBoundary';
import Layout from '../Layout/Layout';
import Complaint from '../views/complaint log/ComplaintLog';
import Dashboard from '../views/dashboard/Dashboard';
import Login from '../views/login/login.jsx';
import Resolve from '../views/resolve complaint/ResolveComplaint';
import ProtectedRoute from './protected-routes';
import { appRoutes } from './routes.jsx';

const routeConfig = [
  {
    path: appRoutes.UNKNOWN,
    element: 'Page Not Found',
  },
  {
    path: appRoutes.LOGIN,
    element: (
      <Suspense fallback={<Loader />}>
        <Login />
      </Suspense>
    ),
  },
  {
    path: appRoutes.DASHBOARD,
    element: (
      <ProtectedRoute expectedRoles={['admin']}>
        <Layout>
          <ErrorBoundary>
            <Dashboard />
          </ErrorBoundary>
        </Layout>
      </ProtectedRoute>
    ),
  },
  {
    path: appRoutes.COMPLAINT,
    element: (
      <ProtectedRoute expectedRoles={['admin']}>
        <Layout>
          <ErrorBoundary>
            <Complaint />
          </ErrorBoundary>
        </Layout>
      </ProtectedRoute>
    ),
  },
  {
    path: appRoutes.RESOLVE,
    element: (
      <ProtectedRoute expectedRoles={['admin']}>
        <Layout>
          <ErrorBoundary>
            <Resolve />
          </ErrorBoundary>
        </Layout>
      </ProtectedRoute>
    ),
  },
];

export default routeConfig;
