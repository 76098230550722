import { Box, Grid, Paper, Typography } from '@mui/material';
import { useEffect, useState } from 'react';
import abstract from '../../assets/images/abstract.svg';
import customerCareService from '../../services/customer_care_services';

const BoxStyles = {
  position: 'relative',
  // height: '100%',
  width: 'calc(100% - 40px)',
  margin: '20px',
};

function Dashboard() {
  const [fetchedData, setFetchedData] = useState({ total: '', pending: '', resolved: '' });

  useEffect(() => {
    customerCareService
      .Dashboard()
      .then((res) => {
        setFetchedData({
          total: res?.data?.data?.totalComplaints,
          pending: res?.data?.data?.notResolvedComplaints,
          resolved: res?.data?.data?.resolvedComplaints,
        });
      })
      .catch((err) => {
        // console.log('err', err);
      });
  }, []);

  return (
    <Box sx={BoxStyles}>
      <Grid container spacing={2}>
        <Grid item xs={12} md={4}>
          <Paper className='relative rounded-2xl bg-white h-[182px] overflow-hidden'>
            <Box className='absolute top-0 right-0 x'>
              <img src={abstract} />
            </Box>
            <Typography className=' absolute left-[29px] top-6 font-poppins font-bold text-[16px] leading-[20px] text-[#B5B5C3]'>
              Total Complaints
            </Typography>
            <Box className='flex absolute left-[29px] bottom-[65px]'>
              <Typography className='font-poppins font-bold text-[20px] leading-[30px] w-16 h-[30px] text-[#7928CA] ml-1'>
                {fetchedData?.total ?? 'loading...'}
              </Typography>
            </Box>
          </Paper>
        </Grid>
        <Grid item xs={12} md={4}>
          <Paper className='relative rounded-2xl bg-white h-[182px] overflow-hidden'>
            <Box className='absolute top-0 right-0 x'>
              <img src={abstract} />
            </Box>
            <Typography className=' absolute left-[29px] top-6 font-poppins font-bold text-[16px] leading-[20px] text-[#B5B5C3]'>
              Resolved Complaints
            </Typography>
            <Box className='flex absolute left-[29px] bottom-[65px]'>
              <Typography className='font-poppins font-bold text-[20px] leading-[30px] w-16 h-[30px] text-[#7928CA] ml-1'>
                {fetchedData?.resolved ?? 'loading...'}
              </Typography>
            </Box>
          </Paper>
        </Grid>
        <Grid item xs={12} md={4}>
          <Paper className='relative rounded-2xl bg-white h-[182px] overflow-hidden'>
            <Box className='absolute top-0 right-0 x'>
              <img src={abstract} />
            </Box>
            <Typography className=' absolute left-[29px] top-6 font-poppins font-bold text-[16px] leading-[20px] text-[#B5B5C3]'>
              Pending Complaints
            </Typography>
            <Box className='flex absolute left-[29px] bottom-[65px]'>
              <Typography className='font-poppins font-bold text-[20px] leading-[30px] w-16 h-[30px] text-[#7928CA] ml-1'>
                {fetchedData?.pending ?? 'loading...'}
              </Typography>
            </Box>
          </Paper>
        </Grid>
      </Grid>
    </Box>
  );
}

export default Dashboard;
