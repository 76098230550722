import { BrowserRouter as Router } from 'react-router-dom';
import ModalProvider from './components/modalProvider/modalProvider';
import RouteRender from './Routes/route-render';
import { ContextProvider } from './stateManagement/context';

function App() {
  return (
    <>
      <Router>
        <ContextProvider>
          <ModalProvider>
            <RouteRender />
          </ModalProvider>
        </ContextProvider>
      </Router>
    </>
  );
}

export default App;
